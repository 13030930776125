<template>
    <div>
        <div>
            <van-cell-group title="编辑荣誉">
                <van-field v-model="model.NAME" type="text" label="荣誉名称" placeholder="请输入荣誉名称" />
                <van-field label="荣获日期" :value="model.OBTAIN_DT | datetime('YYYY-MM-DD')" @click="time.show = true"
                    placeholder="请选择荣获日期" is-link />
                <van-popup v-model="time.show" round position="bottom">
                    <van-datetime-picker type="date" :min-date="time.minDate" v-model="time.curDate"
                        @cancel="time.show = false" @confirm="timeConfirm" />
                </van-popup>
            </van-cell-group>
            <van-cell-group title="上传图片">
                <van-uploader :after-read="afterRead" multiple v-model="picList" @delete="deleteImg" max-count="9"
                    style="margin: 10px 0px 0px 10px" />
            </van-cell-group>
            <div style="margin: 15px">
                <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                    loading-text="正在提交。。。">确认编辑</van-button>
                <br />
                <van-button plain type="default" size="large" icon="cross" block
                @click="$router.go(-1)">取消返回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
export default {
    data() {
        return {
            id: this.$route.query.id,
            model: {},
            images: [],
            picList: [],
            ret: {
                loading: false
            },
            time: {
                show: false,
                minDate: moment().add(-1, 'y').toDate(),
                curDate: moment().toDate(),
            },
        }
    },
    created() {
        this.getHonor();
    },

    methods: {
        timeConfirm(val) {
            this.time.show = false;
            console.log('val',val)
             
            this.model.OBTAIN_DT = moment(val).format('YYYY-MM-DD');
        },
        getHonor() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/ORG/TeaHonorApi/GetFirst",
                data: { id: this.id },
                completed: function (res) {
                    self.model = res
                    self.picList = res.IMAGELIST.map(x => { return { url: x.Value, isImage: true } })
                    self.images = res.IMAGELIST.map(x => x.Key)
                },
            });
        },
        afterRead(files) {
            let self = this
            if (!Array.isArray(files)) {
                files = [files]
            }
            files.forEach(item => {
                let b64Arr = item.content.split(',')
                this.whale.remote.getResult({
                    url: "/api/Mobile/ORG/TeaHonorApi/UploadHonorImage",
                    data: { base64: b64Arr[1] },
                    completed(its) {
                        self.images.push(its.DATA)
                    },
                })
            })
            setTimeout({}, 100)
        },
        deleteImg(files, { index }) {
            this.images.splice(index, 1)
        },
        submit() {
            let self = this;
            self.model.IMAGES = self.images.join(',')

            if (!self.model.NAME) {
                Toast.fail("请输入荣誉名称")
                return;
            }
            if (!self.model.OBTAIN_DT) {
                Toast.fail("请选择获取日期")
                return;
            }
            self.ret.loading = true;
            this.whale.remote.getResult({
                url: "/api/Mobile/ORG/TeaHonorApi/Update",
                data: this.model,
                finally() {
                    self.ret.loading = false;
                },
                completed() {
                    self.$dialog.alert({
                        message: "教师荣誉更新成功",
                        confirmButtonText: "确定",
                    }).then(() => {
                        self.$router.go(-1);
                        
                    })
                }
            })
        },
    }
}
</script>
<style scoped></style>